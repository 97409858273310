* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Outfit', sans-serif;
}
.login-container {
    background: url("./assets/login_background.svg") no-repeat fixed;
    background-size: cover;
    min-height: 85vh;
    width: 100%;
  }
  .container {
    background: transparent;
    height: 100vh;
    width: 100%;
    position: relative;
  }
  @media screen and (min-width: 680px) {
    .login-container {
      background: url("./assets/web_background.svg") no-repeat fixed;
      background-size: cover;
      min-height: 85vh;
      width: 100%;
    }
      .container {
        background: url("./assets/web_background.svg") no-repeat fixed;
        background-size: cover;
        height: 100vh;
        width: 100%;
      }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #92CE08;
    border-radius: 40px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .sw-delete-title {
    color: #F90000 !important;
  }
  .sw-delete-cancel {
    background-color: #fff !important;
    border: 1px solid #F90000 !important;
    color: #F90000 !important;
  }
  .sw-delete-confirm {
    background-color: #F90000 !important;
    color: #fff !important;
  }
  .role-container .MuiPaper-root   {
    max-width: 100% !important;
  }
  .stock-container .MuiPaper-root  {
    max-width: 100% !important;
    min-width: 80% !important;
  }
  .MuiDataGrid-root {
    background:#fff !important;
  }
  .MuiDataGrid-root .MuiDataGrid-row:hover {
    background:#E8FFFB !important;
  }
  .MuiDataGrid-cell--withRenderer, .MuiDataGrid-cell {
    outline: none !important;
  }
  .produits-container .MuiPaper-root  {
    min-width: 900px !important;
  }
  
  .commandes-container .MuiDataGrid-row:hover {
    background: #f8f8f8 !important;
  }
  .commandes-container .MuiDataGrid-row.Mui-selected {
    background: #E8FFFB !important;
  }
  input, select {
    -webkit-background-clip: text !important;
    background-clip:  text !important;
  }
  main {
    background-color: transparent !important;
  }
  .MuiDialogContent-root {
    padding: 0 24px 20px !important;
  }